<template>
  <div class="green-block price">
    <div
      v-if="item.properties && item.properties.length"
      class="price-half price-properties"
    >
      <div
        v-for="(property, index) in item.properties"
        :key="index"
        class="price-property"
      >
        <div
          class="price-property-icon glyphicon"
          :class="[`glyphicon-${property.icon}`]"
        ></div>
        <div>{{ property.text }}</div>
      </div>
    </div>
    <div class="price-half">
      <div class="price-text">
        <span v-if="item.price.prepend">
          {{ item.price.prepend }}
        </span>
        {{ item.price.value }}
        <span v-if="item.price.append">
          {{ item.price.append }}
        </span>
      </div>
      <a
        v-if="item.buttonLink && item.buttonLinkText"
        class="button price-button-link"
        :href="item.buttonLink"
      >
        {{ item.buttonLinkText }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Price',
  props: {
    item: {
      type: Array,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.price {
  .price-properties {
    margin-bottom: 10px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;

    .price-property {
      display: flex;
      font-size: 14px;

      .price-property-icon {
        margin-right: 10px;
      }
    }
    .price-property:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .price-text {
    font-size: 60px;
    line-height: 100px;
    color: var(--primary);
    text-align: center;

    span {
      font-size: 12px;
    }
  }

  .price-button-link {
    width: 100%;
    margin-top: 0;
    padding: 10px 0;
  }
}
</style>