<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>VPS Average</h1>
        <Price :item="data.staticData.prices.SSLCertificatesPrices[2]"/>
        <h2>VPS Average</h2>
        <p>Dit pakket voor VPS is de geschikte keuze voor veeleisende ondernemingen. Host meerdere websites en/of applicaties, zonder dat u hoeft te investeren in een fysieke server. Wel het gemak van eigen virtuele serverruimte en het besturingssysteem naar keuze, zonder dat daar aan de andere kant een grote investering tegenover hoeft te staan.</p>
        <p>Het pakket voor een VPS is geschikt voor technisch veeleisende ondernemingen. We bieden de vrijheid van een virtuele server, op basis van 100GB aan dataverkeer per maand. U heeft 50GB aan HDD opslagruimte tot uw beschikking en kunt zelf het besturingssysteem kiezen en installeren.</p>
        <h3>VPS: virtual private server</h3>
        <p>Kies voor de vrijheid van een VPS, een virtual private server waarmee u als het ware een eigen server tot uw beschikking krijgt. Niet de investering in een fysieke server, dankzij het voordelig gebruik van een virtueel afgebakend onderdeel van onze hoogwaardige servers. De professionele betrouwbaarheid van een VPS op basis van A-merk apparatuur, waar u voordelig gebruik van kunt maken.</p>
        <ul>
          <li>Vrijheid van een virtuele server</li>
          <li>100GB dataverkeer voor al uw websites of applicaties</li>
          <li>50GB HDD opslagruimte</li>
          <li>Kies zelf het besturingssysteem</li>
        </ul>
        <h3>Kies zelf het besturingssysteem (OS)</h3>
        <p>Op een VPS kiest u zelf het besturingssysteem (OS) waarmee u wilt werken. Dit betekent de vrijheid van een eigen virtuele server, bijvoorbeeld om Linux te installeren. Aan de andere kant heeft u daar geen eigen fysieke server voor nodig, waardoor we u het beste bieden van twee werelden.</p>
        <p><i>“De VPS met 100GB dataverkeer per maand voldoet ruim aan onze eisen, zodat we daar geen omkijken naar hebben. Snel, betrouwbaar en oerdegelijk: absolute aanrader voor andere organisaties”,</i>, aldus een van onze tevreden klanten.</p>
        <h3>VPS hosting met 3 CPU cores</h3>
        <p>De VPS biedt hosting op basis van 3 CPU cores, zodat u kunt rekenen op maximale rekenkracht. Het gemak van een eigen virtuele server zonder de investering die u daarbij zou verwachten. Maak gebruik van 50GB HDD opslagruimte, 4GB RAM en 100GB dataverkeer per maand, zodat u zich nergens zorgen over hoeft te maken. Direct online te bestellen.</p>
      </div>
      <CloudServersRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import CloudServersRightSideBlocks from '@/components/CloudServersRightSideBlocks.vue';

export default {
  name: 'VPSAverage',
  components: {
    Breadcrumbs,
    Price,
    CloudServersRightSideBlocks,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>